import { NormalizedCategory } from '@wix/communities-blog-client-common';
import model from './model';
import { BlogFacade } from './utils/blog.facade';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  const facade = new BlogFacade(flowAPI);

  $w('#categoriesElement').onClick((event: any) => {
    facade.navigateToCategoryFeed(event.target.value[0]);
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setCategories: (normalizedCategories: NormalizedCategory[]) => {
        const categories = normalizedCategories.map((cat) => ({
          label: cat?.menuLabel,
          value: cat?.slug,
        }));
        $w('#categoriesElement').options = categories;
      },
    },
  };
});
