import type { BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import model from './model';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setTags: (tags: BlogTag[]) => {
        $w('#tagsElement').options = tags;
      },
    },
  };
});
