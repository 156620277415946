import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { blogAppDefId } from '../../../external/common/constants/apps';
import { TPA_PAGE_ID_BLOG } from '../../../external/common/constants/tpa-pages';

export class BlogFacade {
  constructor(private readonly flowAPI: PlatformControllerFlowAPI) {}

  private async makeCategoryFeedUrl(categorySlug: string) {
    const { url } =
      await this.flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
        sectionId: TPA_PAGE_ID_BLOG,
        appDefinitionId: blogAppDefId,
      });
    if (url) {
      return `${url}/categories/${categorySlug}`;
    }
  }

  async navigateToCategoryFeed(categorySlug: string) {
    const url = await this.makeCategoryFeedUrl(categorySlug);

    if (url) {
      this.flowAPI.controllerConfig.wixCodeApi.location.to?.(url);
    }
  }
}
